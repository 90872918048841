import { Form, Input, Select } from "antd";
import { useEffect, useState } from "react";
import { useGlobalState } from "../../../components/global";

export default function CacheCleanForm({ form, systemCode }: any) {
  const { jsonRequest, currentTenant } = useGlobalState();
  const [caches, setCaches] = useState([]);

  useEffect(() => {
    getCacheEnum();
  }, []);

  const getCacheEnum = () => {
    jsonRequest
      .post("/api/request", {
        path: "/v1/enums",
        method: "get",
        application_key: "zy:application::knowledge-foundation",
        tenant_meta: currentTenant,
        data: { type: "clear_cache" },
      })
      .then((resp: any) => resp.json())
      .then(({ data }) => {
        setCaches(data);
      });
  };

  return (
    <Form form={form} size="middle" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }}>
      <Form.Item name="systemCode" hidden initialValue={systemCode}>
        <Input disabled />
      </Form.Item>
      <Form.Item name="key" label="缓存类型" rules={[{ required: true, message: "不能为空" }]}>
        <Select options={caches.map((e: any) => ({ value: e.enumKey, label: e.enumName }))} />
      </Form.Item>
    </Form>
  );
}
