import { Button, Form, Input, Select } from "antd";
import { useEffect, useState } from "react";

import { useGlobalState } from "../../../components/global";

export default function FieldForm({ form, detail, parent, tenants, types, quickPushChild }: any) {
  const { id, fieldType } = detail;

  const { user, navActiveKey, jsonRequest, currentTenant, signOut, setTenant } = useGlobalState();
  const [groups, setGroups] = useState<any>([]);

  useEffect(() => {
    form.resetFields();
    if (detail.systemCode) getCateGroups(detail.systemCode);
  }, []);

  const getCateGroups = (systemCode: any) => {
    if (!systemCode) return;
    jsonRequest
      .post("/api/request", {
        path: "/v1/content/cates/groups",
        method: "get",
        application_key: "zy:application::knowledge-foundation",
        tenant_meta: currentTenant,
        data: { systemCode },
      })
      .then((resp: any) => resp.json())
      .then(({ data }) => {
        setGroups(data);
      });
  };

  return (
    <Form
      form={form}
      initialValues={detail}
      size="small"
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 14 }}
    >
      <Form.Item name="id" hidden>
        <Input disabled />
      </Form.Item>
      <Form.Item name="parentId" hidden>
        <Input disabled />
      </Form.Item>
      <Form.Item
        label="知识体系"
        name="systemCode"
        rules={[{ required: true, message: "不能为空" }]}
      >
        <Select
          disabled={id || !!parent.id}
          onChange={(v) => getCateGroups(v)}
          options={tenants.map((e: any) => ({ value: e.systemCode, label: e.systemName }))}
        />
      </Form.Item>
      <Form.Item label="上级字段名">
        <span>{detail.parentName || parent.fieldName || "无"}</span>
      </Form.Item>
      <Form.Item label="上级字段Key">
        <span>{detail.parentKey || parent.fieldKey || "无"}</span>
      </Form.Item>
      <Form.Item label="类型" name="fieldType" rules={[{ required: true, message: "不能为空" }]}>
        <Select
          disabled={id || parent.fieldType}
          options={types.map((e: any) => ({ value: e.enumKey, label: e.enumName }))}
        />
      </Form.Item>
      {fieldType === "content_cate_info" && (
        <Form.Item
          label="所属分组"
          name="parentId"
          hidden={detail?.parentId > 0}
          rules={[{ required: true, message: "不能为空" }]}
        >
          <Select
            disabled={id || !!parent.id}
            labelInValue
            options={groups.map((e: any) => ({ value: e.fieldId, label: e.groupName }))}
          />
        </Form.Item>
      )}
      <Form.Item
        label="字段名称"
        name="fieldName"
        rules={[{ required: true, message: "不能为空" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="字段Key" name="fieldKey" rules={[{ required: true, message: "不能为空" }]}>
        <Input disabled={id} />
      </Form.Item>
      <Form.Item label="字段描述" name="description">
        <Input />
      </Form.Item>
      {id && (
        <Form.Item label=" " colon={false}>
          <Button type="link" onClick={quickPushChild}>
            添加下级字段
          </Button>
        </Form.Item>
      )}
    </Form>
  );
}
