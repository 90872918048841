import { LoadingOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useGlobalState } from "../../../components/global";

// 在useEffect中通过字段id请求字段描述
// 请求结束前返回Loading 请求结束后返回字段描述内容
export default function FieldDescription({ systemCode, fieldType, fieldId }: any) {
  const { jsonRequest, currentTenant } = useGlobalState();
  const [loading, setLoading] = useState<boolean>(true);
  const [description, setDescription] = useState<string>("");

  useEffect(() => {
    jsonRequest
      .post("/api/request", {
        path: `/v1/system-fields`,
        method: "get",
        application_key: "zy:application::knowledge-foundation",
        tenant_meta: currentTenant,
        data: { systemCode, fieldType, fieldId },
      })
      .then((resp: any) => resp.json())
      .then(({ data }) => {
        setDescription(data[0]?.description ?? "无");
        setLoading(false);
      });
  }, []);

  return loading ? <LoadingOutlined /> : <div>{description}</div>;
}
